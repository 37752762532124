import PfComponent from '../PfComponent';


export class SearchTag extends PfComponent{
	constructor(e, b){
		super(e, b);

		this.form = this.element.querySelector('form');
		this.inputSearch = this.element.querySelector('.input-search');

		this.submitSearch = this.submitSearch.bind(this);

		this.autocomplete = this.element.querySelector('.input-autocomplete');

	}
	init(){
	}

	submitSearch = (event) => {
		// if(this.autocomplete.innerHTML != this.inputSearch.value && this.autocomplete.innerHTML.length > this.inputSearch.value.length) this.inputSearch.value = this.autocomplete.innerHTML
		event.preventDefault();
		window.location.href = this.form.action + '/' + encodeURIComponent(this.inputSearch.value);
	}

	getAutocomplete = async (e) => {
		const response = await fetch(this.wpEndpoint + ['pf/v1', 'autocomplete'].join('/'), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'value': e.srcElement.value
			})
		});
		const data = await response.json();

		if(data.success) this.autocomplete.innerHTML = data.result;
		else this.autocomplete.innerHTML = '';
	}

	attach(){
		this.form.addEventListener('submit', this.submitSearch);
		this.inputSearch.addEventListener('keyup', this.getAutocomplete);
	}

	detach(){
		this.form.removeListener('submit', this.submitSearch);
		this.inputSearch.removeEventListener('keyup', this.getAutocomplete);
	}
}