import Video from './Video';

let apiReady = false;
const toInit = [];

const insertAPI = function () {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(tag, firstScript);
}

const original = window['onYouTubeIframeAPIReady'];
window['onYouTubeIframeAPIReady'] = () => {
    toInit.forEach((elem) => {
        elem.init();
    })
    apiReady = true
    if (typeof original == 'function') original();
}
insertAPI();

export default class YoutubeVideo extends Video {
    constructor(parent, options = {}) {
        super();
        this.options = options;
        this.videoId = options.id ? options.id : '';
        if (apiReady) {
            this.init();
        } else {
            toInit.push(this);
        }
    }

    init = () => {
        this.player = new YT.Player('video-player', {
            height: '100%',
            width: '100%',
            videoId: this.videoId,
            playerVars: {
                'controls': 0,
                'modestbranding': 1,
                'showinfo': 0
            },
            events: {
                'onReady': this.onPlayerReady,
                'onStateChange': this.onStateChange
            }
        })
    }

    onPlayerReady = (event) => {
        if (this.options.autoplay) event.target.playVideo()
        this._mute = this.player.isMuted();
        this.loadCallback();
    }

    onStateChange = (event) => {
        this._playing = event.data == YT.PlayerState.PLAYING;
        this.changeCallback();
    }

    play = () => {
        this.player.playVideo();
        this._playing = true;
    }
    pause = () => {
        this.player.pauseVideo();
        this._playing = false;
    }

    seekTo = (relativeTime,) => {
        this.player.seekTo(relativeTime * this.duration, true);
    }

    preview(relativeTime) {
        this.player.seekTo(relativeTime * this.duration, false);
    }

    mute = () => {
        this.player.mute()
    }
    unMute = () => {
        this.player.unMute()
    }

    get volume() {
        return this.player.getVolume;
    }

    set volume(val) {
        this.player.setVolume(val);
    }

    get duration() {
        return this.player.getDuration();
    }

    get time() {
        return this.player.getCurrentTime();
    }
}