import { Module, ViewportModule, PageModule } from 'libs';
import { gsap}        from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export class AnimationsModule extends Module {
    constructor(bootstrap) {
        super(bootstrap);
        this.dependencies.add(ViewportModule);
        this.dependencies.add(PageModule);

    }
    init(){
        this.viewport = this.bootstrap.get(ViewportModule).viewport;
        this.pageModule = this.bootstrap.get(PageModule);   
    }


    buildAnimations = () => {
        //SIMPLE Reveal
        this.animationsReveal();
        //Scale down image reveal
        this.animationImage();
        //Simple reveal items inside stagger
        this.animationRevealInside();
        //Anim CTA
        this.animationsCta();
        // Anim Slideshow content apparition
        this.animationSlideshow();
        //Parallax IMG
        this.parallaxImg()

    };

    animationsReveal(){
         let itemReveal = this.pageModule.current.querySelectorAll('[data-animation="reveal"]');
         itemReveal.forEach(element => {
             let t = new gsap.timeline({
                 scrollTrigger: {
                     trigger: element,
                     toggleActions: 'play complete complete complete',
                     onEnter: (anim) => (
                         anim.progress == 1
                     ) ? t.progress(1) : t.play(),
                 },
             });
             t.set(element, {y: 0}, 0);
             t.fromTo(element, 1.2, {
                 y: 250,
             }, {y: 0, immediateRender: false, ease: "power4.out", clearProps: 'transform'}, 0);
             t.fromTo(element, 0.6, {alpha: 0}, {alpha: 1, clearProps: 'alpha'}, 0);

         });
    }

    animationRevealInside(){

        let itemRevealStagger = this.pageModule.current.querySelectorAll('[data-animation="reveal-inside"]');
        itemRevealStagger.forEach(element => {
            let els = element.querySelectorAll(':scope > *')
            let t = new gsap.timeline({
                scrollTrigger: {
                    trigger: element,
                    end: 'bottom top',
                    toggleActions: 'play complete complete complete',
                    onEnter: (anim) => (
                        anim.progress == 1
                    ) ? t.progress(1) : t.play(),
                },
            });
            t.set(els, {y: 0, alpha: 0}, 0);
            t.fromTo(els, 1.2, {
                y: 130,
            }, {y: 0, immediateRender: false, ease: "expo.out",stagger:0.12, clearProps: 'transform'}, 0);
            t.fromTo(els, 0.6, {alpha: 0}, {alpha: 1,stagger:0.12, clearProps: 'alpha'}, 0);

        });
    }

    animationsCta(){
        let ctas = this.pageModule.current.querySelectorAll('[data-animation="reveal-cta"]');
        ctas.forEach(element => {
            ScrollTrigger.create({
                trigger: element,
                once:true,
                toggleClass: "ctaVisible",
            });
         });
    }

    animationSlideshow(){
         let itemReveal = this.pageModule.current.querySelectorAll('[data-animation="reveal-slideshow"]');
         itemReveal.forEach(element => {
             let t = new gsap.timeline({
                 scrollTrigger: {
                     trigger: element,
                     toggleActions: 'play complete complete complete',
                     onEnter: (anim) => (
                         anim.progress == 1
                     ) ? t.progress(1) : t.play(),
                 },
             });
             t.fromTo(element, 1.2, {
                 x: "25vw",
             }, {x: 0, immediateRender: false, ease: "power4.out", clearProps: 'transform'}, 0);
            t.fromTo(element, 0.6, {alpha: 0}, {alpha: 1, clearProps: 'alpha'}, 0);
         });
    }

    animationImage(){
         let itemReveal = this.pageModule.current.querySelectorAll('[data-animation="reveal-image"]');
         itemReveal.forEach(element => {
             let t = new gsap.timeline({
                 scrollTrigger: {
                     trigger: element,
                     toggleActions: 'play complete complete complete',
                     onEnter: (anim) => (
                         anim.progress == 1
                     ) ? t.progress(1) : t.play(),
                 },
             });
             t.fromTo(element, 1.2, {
                 scale: 1.5,
             }, {scale: 1, immediateRender: false, ease: "power4.out", clearProps: 'transform'}, 0);

         });
    }

    parallaxImg(){
        let $content = this.pageModule.current.querySelectorAll('[data-animation="parallax"]');
        $content.forEach(element => {           
            let delta = (element.dataset.delta)?element.dataset.delta:element.offsetHeight/5;
            let t = gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        scrub: true
                    }
                });
                const animated = element.querySelector('img') ? element.querySelector('img') : element;
                t.fromTo(animated,{y: -delta },{y: delta},0);
         });


    }

    async before(){
        ScrollTrigger.getAll().forEach(st => {
            if(st.vars.type && st.vars.type == "permanent") return;
            st.kill()
        });

    }

    async after() {
       this.buildAnimations();

    }
}