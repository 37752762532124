import PfComponent from "../PfComponent";

const DEG_2_RAD = Math.PI / 180;

export class StatsCircular extends PfComponent {
    constructor(e, b) {
        super(e, b);

        this.graph = this.element.querySelector('.graph');
        this.svgWrapper = this.graph.querySelector('.svg-wrapper');
        this.stats = JSON.parse(this.graph.dataset.stats);

        this.radius = 114;
        this.svg = this.createSvg(this.radius * 2, this.radius * 2);
        this.preparedStats = this.prepareStats(this.stats);
        this.buildSections(this.preparedStats);

        this.svgWrapper.appendChild(this.svg);
    }

    prepareStats(stats) {
        let previousAngle = 0;

        return stats.map(stat => {
            const fromAngle = previousAngle;
            const toAngle = fromAngle + (parseFloat(stat.chiffre)) * (360 / 100);
            previousAngle = toAngle;
            const rgb = this.hexToRgb(stat.couleur);
            return {
                percent: parseFloat(stat.chiffre),
                fill: stat.couleur,
                textFill: ((rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 186) ? 'rgba(4, 22, 49, 0.4)' : 'white',
                text: stat.label || stat.chiffre,
                fromAngle,
                toAngle,
            }
        })
    }

    createSvg(width, height) {
        const viewBox = `0 0 ${width} ${height}`;
        const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgElement.setAttribute('viewBox', viewBox);
        svgElement.setAttribute('width', width);
        svgElement.setAttribute('height', height);

        return svgElement;
    }

    hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    buildSections(stats) {
        // Sections
        stats.forEach((stat) => {
            const path = document.createElementNS("http://www.w3.org/2000/svg", 'path');

            let xCircle1 = this.radius + this.radius * Math.cos(stat.fromAngle * DEG_2_RAD);
            let yCircle1 = this.radius + this.radius * Math.sin(stat.fromAngle * DEG_2_RAD);
            let xCircle2 = this.radius + this.radius * Math.cos(stat.toAngle * DEG_2_RAD);
            let yCircle2 = this.radius + this.radius * Math.sin(stat.toAngle * DEG_2_RAD);
            const arcFlag = stat.toAngle - stat.fromAngle <= 180 ? '0' : '1';

            let d = [
                `M${this.radius} ${this.radius}`,
                `L${xCircle1} ${yCircle1}`,
                `A${this.radius} ${this.radius} 0 ${arcFlag} 1 ${xCircle2} ${yCircle2}`,
                `L${this.radius} ${this.radius}`,
                `Z`,
            ];
            path.setAttribute('d', d.join(' '));
            path.setAttribute('fill', stat.fill);

            this.svg.appendChild(path);
        })

        // Texts
        stats.forEach((stat) => {
            const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

            let xSectionCenter = this.radius + this.radius * Math.cos((stat.toAngle + (stat.fromAngle - stat.toAngle) * 0.5) * DEG_2_RAD)
            xSectionCenter = (xSectionCenter + this.radius) / 2;
            let ySectionCenter = this.radius + this.radius * Math.sin((stat.toAngle + (stat.fromAngle - stat.toAngle) * 0.5) * DEG_2_RAD)
            ySectionCenter = (ySectionCenter + this.radius) / 2;
            text.setAttribute('x', `${xSectionCenter}`);
            text.setAttribute('y', `${ySectionCenter}`);
            text.setAttribute('text-anchor', `middle`);
            text.setAttribute('style', 'font-family: "PT Sans", Arial, sans-serif; font-size: 12px; font-style: italic;');
            text.setAttribute('fill', `${stat.textFill}`);
            text.innerHTML = `${stat.text}`;

            this.svg.appendChild(text);
        })
    }
}