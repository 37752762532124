import PfComponent from '../PfComponent';
import {gsap} from "gsap";

export class Header extends PfComponent {
	constructor(e, b) {
		super(e, b);

		this.toggle = this.element.querySelector('.toggle');

		// Mobile menu (burger)
		this.menu = this.element.querySelector('.menu');
		this.headerBar = this.element.querySelector('.header-bar');
		this._menuVisible = false;
		this._expanded = true;
		this.lastScrollPos = 0;
		this.direction = 0;
		this.scroller = document.scrollingElement;

		// Search Bar
		this.searchVisible = false;
		this.searchBar = this.element.querySelector('#searchbar');
		this.searchBtns = this.element.querySelectorAll('.toggle-search');

		this.searchSection = document.querySelector('section#search-container');
		this.isSearchPage = !!(this.searchSection);

		this.showSearchBar = this.showSearchBar.bind(this);
		this.hideSearchBar = this.hideSearchBar.bind(this);
		this.toggleSearchBar = this.toggleSearchBar.bind(this);

		this.tToggle;
	}

	init() {
		//if (this.scroller.scrollTop < 100 && this.isSearchPage) this.showSearchBar();
	}

	showSearchBar() {
		const tl = gsap.timeline({paused: true});

		
		this.searchVisible = true;
		this.searchBtns.forEach((el) => {
			el.classList.add('active');
		});
		tl.fromTo(this.searchBar, {display: "block", yPercent: -100}, {
			yPercent: 0,
			ease: 'power3.out',
			duration: 0.7
		}, 0)
		tl.fromTo(this.searchBar, {opacity: 0}, {opacity: 1, ease: 'none', duration: 0.35}, 0);
		return tl.play();
	}

	toggleSearchBar(event) {
		event.stopPropagation();	
		if(this.tToggle) this.tToggle.seek("-=0", false)
		this.tToggle = gsap.timeline({paused: true});
		if (this.searchVisible) {
			this.tToggle.add(this.hideSearchBar(),0);
		} else {
			this.tToggle.add(this.showSearchBar(),0);
			
		}
		this.tToggle.play();
	}

	hideSearchBar() {
		const tl = gsap.timeline({paused: true});

	
		this.searchVisible = false;
		this.searchBtns.forEach((el) => {
			el.classList.remove('active');
		})
		tl.fromTo(this.searchBar, {yPercent: 0}, {
			yPercent: -100,
			display: 'none',
			ease: 'power3.out',
			duration: 0.7
		}, 0)
		tl.fromTo(this.searchBar, {opacity: 1}, {opacity: 0, ease: 'none', duration: 0.35,}, 0);
		return tl.play();
	}

	/**
	 * @private
	 */
	showMenu() {
		this.element.classList.remove('menu-closed');
		setTimeout(() => {
			this.element.classList.add('menu-visible');
		}, 50);
		this.toggle.setAttribute('aria-expanded', 'true');
	}

	/**
	 * @private
	 */
	hideMenu() {
		this.hideSearchBar();
		const menuComputedStyle = getComputedStyle(this.menu);
		this.element.classList.remove('menu-visible');
		const transitionDuration = parseFloat(menuComputedStyle.transitionDuration)
		this.menuTween = gsap.delayedCall(transitionDuration, () => {
			this.element.classList.add('menu-closed');
		})
		this.toggle.setAttribute('aria-expanded', 'false');
	}

	get menuVisible() {
		return this._menuVisible;
	}

	set menuVisible(value) {
		if (this.menuVisible === value) return;
		this._menuVisible = value;
		if (this.menuTween) this.menuTween.kill();
		this.expanded = true;

		if (value) {
			this.showMenu();
		} else {
			this.hideMenu();
		}
	}

	/**
	 * @private
	 */
	expandHeader() {
		const tl = gsap.timeline();
		tl.to(this.headerBar, {
			yPercent: 0,
			ease: 'power3.out',
			duration: 0.7,
		})
		tl.to(this.headerBar, {
			opacity: 1,
			ease: 'none',
			duration: 0.35,
		}, '<')
	}

	/**
	 * @private
	 */
	collapseHeader() {
		if (this.searchVisible && !this.isSearchPage) return;
		this.hideSearchBar();
		const tl = gsap.timeline();
		tl.to(this.headerBar, {
			yPercent: -100,
			ease: 'power3.inOut',
			duration: 0.7,
		})
		tl.to(this.headerBar, {
			opacity: 0,
			ease: 'none',
			duration: 0.35,
		}, '<')
	}

	get expanded() {
		return this._expanded;
	}

	set expanded(value) {
		if (this.expanded === value) return;
		this._expanded = value;

		if (value) {
			this.expandHeader();
		} else {
			this.collapseHeader();
		}
	}

	onToggleClick = () => {
		this.menuVisible = !this.menuVisible;
	}

	onMedia = (e) => {
		this.menuVisible = this.menuVisible && !this.viewport.medias.md
	}

	onScroll = (e) => {
		const scrollPos = this.scroller.scrollTop;
		const direction = (scrollPos - this.lastScrollPos) / Math.abs(scrollPos - this.lastScrollPos);
		if (direction !== this.direction) {
			this.lastDirectionPos = scrollPos;
		}
		const scrollOffset = scrollPos - this.lastDirectionPos;

		this.direction = direction;
		this.lastScrollPos = scrollPos;

		if (this.menuVisible) return;
		if (scrollPos < 100) {
			//if (this.isSearchPage) this.showSearchBar();
			this.expanded = true;
		} else {
			if (direction === 1) {
				if (this.expanded && scrollOffset > 50) {
					this.expanded = false;
				}
			} else {
				if (!this.expanded && scrollOffset < -50) {
					this.expanded = true;
				}
			}
		}
	}

	preventCloseSearch(event) {
		event.stopPropagation();
	}

	onRouteChange = () =>{
        if(this.menuVisible){
            this.menuVisible = false;
        }
		if (this.searchVisible) {
			this.hideSearchBar();
		}
    }

	attach() {
		this.toggle.addEventListener('click', this.onToggleClick);
		document.addEventListener('scroll', this.onScroll);
		this.viewport.on('media', this.onMedia);
		this.searchBtns.forEach((el) => {
			el.addEventListener('click', this.toggleSearchBar);
		});
		if (!this.isSearchPage) {
			window.addEventListener('click', this.hideSearchBar);
			this.searchBar.addEventListener('click', this.preventCloseSearch);
		}
		this.router.on("change", this.onRouteChange);
	}

	detach() {
		this.toggle.removeEventListener('click', this.onToggleClick);
		document.removeEventListener('scroll', this.onScroll);
		this.viewport.off('media', this.onMedia);
		this.searchBtns.forEach((el) => {
			el.removeEventListener('click', this.toggleSearchBar);
		});
		if (!this.isSearchPage) {
			window.removeEventListener('click', this.hideSearchBar);
			this.searchBar.removeEventListener('click', this.preventCloseSearch);
		}
	}


}
