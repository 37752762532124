import PfComponent from '../PfComponent';
import { gsap } from 'gsap';


export class Thematiques extends PfComponent {
    constructor(e, b) {
        super(e, b);
        this.tags = this.element.querySelector('.tags');
        this.line = this.element.querySelectorAll(".line");

    }

    build(){
        let t = new gsap.timeline({
            scrollTrigger:{
                trigger: this.tags,
                scrub: 1,
                start:"top 85%",
                end:"bottom 15%",
                invalidateOnRefresh: true
               
            }
        })

        t.fromTo(this.line,{
            x:(i,item)=>{          
                let elP = item.parentNode;
                const delta = item.offsetWidth - elP.offsetWidth;
                (delta<0) ?  elP.classList.add("text-center") : elP.classList.remove("text-center");
                if(i%2==0){
                    return 0
                }else{
                    return (delta > 0) ? -delta : 0
                }
            }
        },{
            x:(i,item)=>{
                let elP = item.parentNode    
                if(i%2==0){
                    const  delta = item.offsetWidth - elP.offsetWidth
                    return (delta > 0) ? -delta : 0
                }else{
                    return 0
                }
            }, ease: "power1.inOut"
        })
    }


    attach() {
       this.build();
    }

   
}