import Video from './Video';
import Vimeo from '@vimeo/player';

export default class VimeoVideo extends Video  {
	constructor (parent, options) {
		super();
		this.options = options;
		this.player = new Vimeo(parent, {
			controls: false,
			url: options.url ? options.url : '',
			responsive: true
		})
		this._duration = 0;
		this._currentTime = 0;

		this.player.ready().then(() => {
			this.player.getDuration().then(this.init)
		});

		// this.player.on('durationchange', this._setDuration)
		this.player.on('timeupdate', this._setCurrentTime)
	}

	_setDuration = (e) => {
		this._duration = e;
	}

	_setCurrentTime = (e) => {
		this._currentTime = e.seconds;
	}

	init = (e) => {
		this._setDuration(e);
		this.pause();
		this.loadCallback();
	}

	get volume () {

	}
	set volume (val) {

	}

	get duration () {
		return this._duration;
	}

	get time () {
		return this._currentTime;
	}
	play = () => {
		this.player.play();
	}

	pause = () => {
		this.player.pause();
	}

	seekTo = (relativeTime) => {
		this.player.setCurrentTime(relativeTime * this.duration);
	}

	mute = () => {
		this.player.setMuted(true)
	}
	unMute = () => {
		this.player.setMuted(false)
	}
}