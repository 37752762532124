import { Module }           from "libs";
import TwitterWidgetsLoader from "twitter-widgets";

export class EmbedLoaderModule extends Module {
    constructor(b) {super(b);}

    async after() {
        TwitterWidgetsLoader.load((err, twttr) => {
            if (err) return;
            twttr.widgets.load();
        })
    }
}