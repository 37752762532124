import PfComponent from '../PfComponent';
import {gsap} from 'gsap';

class Contact extends PfComponent {
	constructor(e, b) {
		super(e, b);

		this.form = this.element.querySelector('.contact-form-container');
		this.sendBtn = this.element.querySelector('.contact-send-message-container');

		this.formPrenom = this.element.querySelector('.contact-form-prenom');
		this.formMail = this.element.querySelector('.contact-form-mail');
		this.formContent = this.element.querySelector('.contact-form-content');
		this.formID = this.element.querySelector('.contact-form-post-id');

		this.inputs = this.element.querySelectorAll('input:not(.contact-form-post-id), textarea');

		this.confirm = this.element.querySelector('.confirm');
	}

	attach() {
		this.sendBtn.addEventListener('click', this.sendContactForm);

		this.formPrenom.addEventListener('keyup', this.removeMissingClass)
		this.formMail.addEventListener('keyup', this.removeMissingClass)
		this.formContent.addEventListener('keyup', this.removeMissingClass)
	}

	detach() {
		this.sendBtn.removeEventListener('click', this.sendContactForm);

		this.formPrenom.removeEventListener('keyup', this.removeMissingClass)
		this.formMail.removeEventListener('keyup', this.removeMissingClass)
		this.formContent.removeEventListener('keyup', this.removeMissingClass)
	}

	removeMissingClass = (e) => {
		if (e.target.classList.contains('missing')) e.target.classList.remove('missing')
		this.sendBtn.classList.remove('missing');
	}

	sendContactForm = async () => {
		let isComplete = true
		if (this.formPrenom.value === '') {
			this.formPrenom.classList.add('missing');
			isComplete = false
		}
		if (this.formMail.value === '' || !this.isMail(this.formMail.value)) {
			this.formMail.classList.add('missing');
			isComplete = false
		}
		if (this.formContent.value === '') {
			this.formContent.classList.add('missing');
			isComplete = false
		}

		if (isComplete) {
			const response = await fetch(this.wpEndpoint + ['pf/v1', 'form-contact'].join('/'), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					'name': this.formPrenom.value,
					'email': this.formMail.value,
					'content': this.formContent.value,
					'id': this.formID.value
				})
			})

			await response.json();

			this.inputs.forEach((elem) => {
				elem.value = '';
			})

			this.confirm.classList.add('show');
		} else {
			this.sendBtn.classList.add('missing');
		}
	}

	isMail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).trim().toLowerCase());
	}
}

export {Contact};