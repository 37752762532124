export default class Video {
	constructor () {
		this._mute = false;
		this._playing = true;
		this.changeCallback = () => {};
		this.loadCallback = () => {};

	}

	get volume () {

	}
	set volume (val) {

	}

	get autoplay () {

	}
	set autoplay (val) {

	}

	get duration () {
		
	}

	get time () {
 
	}
	set time (relativeTime) {
 		this.seekTo(relativeTime);
	}
	get timeRelative () {
		return this.time / this.duration;
	}

	get playing () {
		return this._playing;
	}
	set playing (val) {
		if(val) this.play()
		else this.pause();
		this._playing = val;
	}

	onChange = (callback) => {
		this.changeCallback = callback
	}

	get muted () {
		return this._mute;
	}
	set muted (val) {
		if(val) this.mute()
		else this.unMute();
		this._mute = val;
	}

	mute = () => {

	}
	unMute = () => {

	}

	init = () => {

	}

	play = () => {

	}

	pause = () => {

	}

	seekTo = (relativeTime) => {

	}

	load = (callback = () => {}) => {
		this.loadCallback = callback;
	}

	timeFormat = (sec) => {
		sec = Math.floor(sec);
		const hours = Math.floor(sec / 3600);
		sec = sec % 3600;
		const min = Math.floor(sec / 60);
		sec = sec % 60;
		return (hours > 0 ? hours+':':'')+(min > 0 ? this.addZero(min) : '0') +':'+this.addZero(sec);
	}

	addZero = (val, n = 2) => {
		val = val + '';
		while(val.length < n) {
			val = '0' + val;
		}
		return val;
	}

}