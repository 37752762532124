import PfComponent from "../PfComponent";

export class StatsSurvey extends PfComponent {
    constructor(e, b) {
        super(e, b);

        this.answers = this.element.querySelector('.answers');
        this.answersItems = this.answers.querySelectorAll('.answer');
        this.survey = this.element.dataset.surveyId;

        this.prepare();
    }

    prepare() {
        if (this.isSurveyVoted()) {
            this.answers.classList.add('voted');
        }
    }

    async sendVote(answer) {
        const response = await fetch(this.wpEndpoint + ['pf/v1', 'vote-survey'].join('/'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'survey_id': this.survey,
                'answer': answer,
            })
        })

        return await response.json();

    }

    onAnswerClick = async (e) => {
        if (this.isSurveyVoted()) return;
        this.setSurveyVoted(true);

        const response = await this.sendVote(e.currentTarget.dataset.answerIndex);

        if (response.stats) {
            const stats = Object.values(response.stats);
            const totalVotes = stats.reduce((sum, val) => sum + parseInt(val), 0)
            this.answersItems.forEach((answerItem, index) => {
                let percent = (totalVotes > 0 && stats[index]) ? stats[index] / totalVotes : 0;
                answerItem.style.setProperty('--percent', percent);

                const percentEl = answerItem.querySelector('.percent span');
                percentEl.innerHTML = Math.round(percent * 100);
            })
            this.answers.classList.add('voted');
        }

    }

    /**
     * Set survey voted state
     *
     * @param {boolean} state
     */
    setSurveyVoted(state) {
        localStorage.setItem(`survey_${this.survey}`, `${state}`);
    }

    isSurveyVoted() {
        return localStorage.getItem(`survey_${this.survey}`) === 'true';
    }

    attach() {
        this.answersItems.forEach(answer => answer.addEventListener('click', this.onAnswerClick))
    }

    detach() {
        this.answersItems.forEach(answer => answer.removeEventListener('click', this.onAnswerClick))
    }
}