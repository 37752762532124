import PfComponent from '../PfComponent';
import YoutubeVideo from '../widgets/YoutubeVideo';
import Html5Video from '../widgets/Html5Video';
import VimeoVideo from '../widgets/VimeoVideo';

export class VideoPage extends PfComponent {
    constructor(e, b) {
        super(e, b);


        this.body = document.body;
        this.html = document.querySelector('html');
        this.pageTop = this.element.querySelector('.top')

        this.videoContainer = this.element.querySelector('.documentary-video');
        this.containerVideoPlayer = this.element.querySelector('#video-player');
        this.controls = this.element.querySelector('#video-controls');
        this.playPause = this.element.querySelector('.play-pause');
        this.playPauseIcon = this.playPause.querySelector('i');
        this.volume = this.element.querySelector('.volume');
        this.progressionBarContainer = this.element.querySelector('.progression-bar-container');
        this.progressionBar = this.element.querySelector('.progression-bar');
        this.progressionBarValue = this.element.querySelector('.progression-bar-value');
        this.currentTime = this.element.querySelector('.current-time');
        this.fullTime = this.element.querySelector('.full-time');
        this.fullscreen = this.element.querySelector('.fullscreen');

        this.mouseIsDown = false;
        this.restartOnUp = false;
        this.isFullscreen = false;
        this.isTheEnd = false;

        this.lastAction = new Date();
        this.noActions = false;

    }

    attach() {

        this.playPause.addEventListener('click', this.playerPlayPause);
        this.playPause.addEventListener('touchend', this.playerPlayPause);

        this.volume.addEventListener('click', this.playerMute);
        this.volume.addEventListener('touchend', this.playerMute);

        this.progressionBarContainer.addEventListener('click', this.playerSeek);
        this.progressionBarContainer.addEventListener('touchend', this.playerSeek);

        this.containerVideoPlayer.addEventListener('click', this.playerPlayPause);
        this.containerVideoPlayer.addEventListener('touchend', this.playerPlayPause);

        /*
        window.addEventListener('mousedown', this.setMoveDown);
        window.addEventListener('touchdown', this.setMoveDown);

        window.addEventListener('mouseup', this.setMoveUp);
        window.addEventListener('touchup', this.setMoveUp);
        */

        this.progressionBarContainer.addEventListener('mousemove', this.playerSeekMove);
        this.progressionBarContainer.addEventListener('touchmove', this.playerSeekMove);

        this.fullscreen.addEventListener('click', this.toggleFullscreen);
        this.fullscreen.addEventListener('touchend', this.toggleFullscreen);

        this.body.addEventListener('fullscreenchange', this.fullScreenchange);

        document.addEventListener('mousemove', this.saveLastAction);
        document.addEventListener('touchmove', this.saveLastAction);
        document.addEventListener('touchdown', this.saveLastAction);

        this.setVideoPlayer();
    }

    detach() {

        this.playPause.removeEventListener('click', this.playerPlayPause);
        this.playPause.removeEventListener('touchend', this.playerPlayPause);

        this.volume.removeEventListener('click', this.playerMute);
        this.volume.removeEventListener('touchend', this.playerMute);

        this.progressionBarContainer.removeEventListener('click', this.playerSeek);
        this.progressionBarContainer.removeEventListener('touchend', this.playerSeek);

        this.containerVideoPlayer.removeEventListener('click', this.playerPlayPause);
        this.containerVideoPlayer.removeEventListener('touchend', this.playerPlayPause);

        /*
        window.removeEventListener('mousedown', this.setMoveDown);
        window.removeEventListener('touchdown', this.setMoveDown);

        window.removeEventListener('mouseup', this.setMoveUp);
        window.removeEventListener('touchup', this.setMoveUp);
        */
        
        this.progressionBarContainer.removeEventListener('mousemove', this.playerSeekMove);
        this.progressionBarContainer.removeEventListener('touchmove', this.playerSeekMove);

        this.fullscreen.removeEventListener('click', this.toggleFullscreen);
        this.fullscreen.removeEventListener('touchend', this.toggleFullscreen);

        this.body.removeEventListener('fullscreenchange', this.fullScreenchange);

        document.removeEventListener('mousemove', this.saveLastAction);
        document.removeEventListener('touchmove', this.saveLastAction);
        document.removeEventListener('touchdown', this.saveLastAction);

        if (this.isFullscreen) document.exitFullscreen();

    }

    setVideoPlayer = () => {
        this.videoSrc = this.containerVideoPlayer.getAttribute('data-src');
        if (this.videoSrc.search(/youtube/g) !== -1) {
            this.videoContainer.classList.add('youtube');
            let id = this.videoSrc.split('/');
            id = id[id.length - 1];
            id = id.split('?')[0];
            this.player = new YoutubeVideo(this.containerVideoPlayer, {
                id: id,
                autoplay: true
            });
        } else if (this.videoSrc.search(/vimeo/g) !== -1) {
            this.videoContainer.classList.add('vimeo');
            this.player = new VimeoVideo(this.containerVideoPlayer, {
                url: this.videoSrc
            });

        } else {
            this.videoContainer.classList.add('html5');
            this.player = new Html5Video(this.containerVideoPlayer, {
                url: this.videoSrc,
                autoplay: true
            })
        }

        this.player.load(() => {
            requestAnimationFrame(this.update);
            this.fullTime.innerHTML = this.player.timeFormat(this.player.duration);
            this.changePlayPauseIcon();
        })
        this.player.onChange(() => {
            this.changePlayPauseIcon();
        })
    }

    saveLastAction = () => {
        this.lastAction = new Date();
    }

    update = () => {
        requestAnimationFrame(this.update);

        this.currentTime.innerHTML = this.player.timeFormat(this.player.time);
        this.progressionBarValue.style.width = (this.player.timeRelative * 100) + '%';

        if (this.player.timeRelative >= 1) {
            if (!this.isTheEnd) {
                this.isTheEnd = true;
            }
        } else {
            this.isTheEnd = false;
        }

        if (this.isFullscreen) {
            const now = new Date();
            const delta = now - this.lastAction;
            if (this.player.playing && delta >= 3000) {
                if (!this.noActions) {
                    this.videoContainer.classList.add('no-activity');
                    this.html.classList.remove('header-visible');
                    this.noActions = true;
                }
            } else {
                if (this.noActions) {
                    this.videoContainer.classList.remove('no-activity');
                    this.html.classList.add('header-visible');
                    this.noActions = false;
                }
            }
        }
    }

    playerPlayPause = () => {
        this.player.playing = !this.player.playing;
        this.saveLastAction();
        this.changePlayPauseIcon();
    }

    changePlayPauseIcon = () => {
        if (this.player.playing) {
            this.playPauseIcon.classList.remove('icon-arrow-right');
            this.playPauseIcon.classList.add('icon-pause');
            this.pageTop.classList.remove('paused');
        } else {
            this.playPauseIcon.classList.remove('icon-pause');
            this.playPauseIcon.classList.add('icon-arrow-right');
            this.pageTop.classList.add('paused');
        }
    }

    playerMute = () => {
        this.volume.classList.toggle('active');
        this.player.muted = !this.player.muted;
    }

    playerSeek = (e) => {
        this.player.seekTo(e.offsetX / e.toElement.offsetWidth);
    }

    playerSeekMove = (e) => {
        if (this.mouseIsDown) {
            if (this.player.playing) {
                this.player.pause()
                this.restartOnUp = true;
            }
            this.playerSeek(e);
        }
    }

    setMoveDown = (e) => {
        this.mouseIsDown = true;
    }

    setMoveUp = (e) => {
        this.mouseIsDown = false;
        if (this.restartOnUp && !this.player.playing) this.player.play();
        this.restartOnUp = false;
    }

    fullScreenchange = (e) => {
        e.stopPropagation()
        if (this.isFullscreen) {
            this.pageTop.classList.toggle('fullscreen-mode');
            this.isFullscreen = false
        } else {
            this.pageTop.classList.toggle('fullscreen-mode');
            this.isFullscreen = true
        }
    }

    toggleFullscreen = (e) => {
        if (this.isFullscreen) {
            document.exitFullscreen();
        } else {
            this.body.requestFullscreen();
        }
    }
}