import PfComponent from '../PfComponent';
import gsap from 'gsap';

export class AboutContributeurs extends PfComponent{
	constructor(e, b){
		super(e, b);
        
        this.reload = this.element.querySelector(".reload");

        this.contrib = Array.from(this.element.querySelectorAll(".contrib"));
        this.innerContent = this.element.querySelector(".inner-content");
        this.index = 0;
	}

	onReload = () => {
        this.index ++;
        this.index = this.index%this.contrib.length;      
        let t = gsap.timeline();
        t.to(this.innerContent,{alpha:0, duration: 0.3},0);
        t.add(()=>{
            this.contrib.forEach(item => item.classList.remove("active"));
            this.contrib[this.index].classList.add("active");
        })
        t.fromTo(this.innerContent,{alpha:0},{alpha:1, duration: 0.3});
    }

	attach(){
		this.reload.addEventListener("click", this.onReload);
	}

	detach(){
		this.reload.removeEventListener("click", this.onReload);
	}
}