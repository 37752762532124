import 'core-js/stable';
import 'regenerator-runtime/runtime';

import gsap               from 'gsap';
import { ScrollTrigger }  from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import MyBoostrap      from './bootstrap/bootstrap';
import * as Components from './components';

import {
    Env,
    AjaxPageModule
}                   from 'libs';

//Gsap default params
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.config({force3D: true});
gsap.defaults({ease: "none", duration: 0.4});

const bootstrap = new MyBoostrap();
bootstrap.components.register(Components);

let viewport = bootstrap.viewport;

viewport.add({
    // addClass: false,
    name: 'xs',
    media: '(min-width: 640px)'
});
viewport.add({
    // addClass: false,
    name: 'sm',
    media: '(min-width: 768px)'
});
viewport.add({
    // addClass: false,
    name: 'md',
    media: '(min-width: 1024px)'
});
viewport.add({
    // addClass: false,
    name: 'lg',
    media: '(min-width: 1480px)'
});
viewport.add({
    // addClass: false,
    name: 'xl',
    media: '(min-width: 1600px)'
});

const ajaxPageModule = bootstrap.get(AjaxPageModule);
const imageLoaderModule = bootstrap.imageLoader;

bootstrap.get(AjaxPageModule).loader.callback = async response => {
    try {
        const title = decodeURI(response.headers.get('X-Meta-Title'));
        const titleHeader =  decodeURI(response.headers.get('X-Header-Title'));        
        if (title) {
            document.querySelector('head title').innerHTML = title;
        }     
        document.querySelector("header #header-title").innerHTML = (titleHeader!=="null")?titleHeader:"";
        document.querySelectorAll("header .header-link").forEach(element => {
            element.classList.remove("active");
            if(element.dataset.title == titleHeader)  element.classList.add("active");
        });
        
    } catch (e) {
        console.error(e);
    }
};
// ajaxPageModule.transform = async (dom) => {
//     dom.style.setProperty('visibility', 'hidden');
//     return dom;
// };


(async function () {
    await bootstrap.run();
    document.body.style.opacity = 1;
})()


if (!Env.device.touch)
    document.querySelector('html').classList.add('notouch');

export default bootstrap;