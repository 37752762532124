import {DefaultComponent, HorizontalSlideshow} from 'libs';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export class SlideshowChiffres extends DefaultComponent{
	constructor(e, b){
		super(e, b);

		this.html 		= document.querySelector('html');

		this.wrapper  	= this.element.querySelector('.wrapper');
		this.slidesWrapper 	= this.element.querySelector('.slides');
		this.slides  	= Array.from(this.element.querySelectorAll('.slide'));
		this.next 		= this.element.querySelector('.next');
		this.prev 		= this.element.querySelector('.previous');
		this.controls 	= this.element.querySelector('.controls');

		this.slideBg 		= this.wrapper.querySelectorAll(".background");
		this.slideBorder	= this.wrapper.querySelectorAll('.border');
		this.slideText		= this.wrapper.querySelectorAll('.slide-text');



		/* this.controls = this.element.querySelector('.controls');
		this.textContainer = this.element.querySelector('.text-container');
		this.text = this.element.querySelector('.text'); */

		this.slideshow = new HorizontalSlideshow(this.slidesWrapper, {
			reference: this.element,
			loop: false,
			swipping: true,
			snapping: true,
			autoResize:false,
			inputOptions:{
				wheel:false
			}
		});

		this.updateButtons();
	}

	resize = () =>{
		this.wrapper.classList.add('layout-inner');
		this.slidesWrapper.classList.add('disabled');
		let maxHeight  = 0;
		let totalWidth = 0;
		this.slides.forEach(slide => {
			slide.style.height = 'auto';
			maxHeight = Math.max(slide.offsetHeight, maxHeight);
			totalWidth += slide.offsetWidth;
		
		});
		console.log(totalWidth, this.element.offsetWidth)
		const disabled = totalWidth < this.element.offsetWidth || !this.html.classList.contains('sm') ;
		// console.log(!this.html.classList.contains('sm'));

		this.slidesWrapper.style.height = disabled ? 'auto' : (maxHeight + 'px');

		this.wrapper.classList.toggle('layout-inner', !disabled);
		if(disabled){
			this.slideshow.detach();
			this.slideshow.unlayout();
			this.slidesWrapper.classList.add('disabled');
			this.slides.forEach(slide => slide.style.height = maxHeight + 'px');
		}
		else{
			this.slides.forEach(slide => slide.style.height = maxHeight + 'px');
			this.slidesWrapper.classList.remove('disabled');
			this.slideshow.attach();
			this.slideshow.rebuild();
		}
		this.updateButtons(disabled);
		
		
	}

	updateButtons = (hide) => {
		if(this.controls){
			this.controls.style.display = typeof hide == "boolean" && hide ? 'none' : 'flex';
			if(this.slideshow.index == 0){
				this.prev.classList.add('disabled');
			}
			else{
				this.prev.classList.remove('disabled');
			}
			if(this.slideshow.index == this.slideshow.max){
				this.next.classList.add('disabled');
			}
			else{
				this.next.classList.remove('disabled');
			}
		}
	}

	attach() {
		if(this.controls){
			this.next.addEventListener('click', this.slideshow.next);
			this.prev.addEventListener('click',this.slideshow.previous);
		}
		this.slideshow.on('change', this.updateButtons);
		this.viewport.on('resize', this.resize);
		this.buildAnimations();
	}

	detach() {
		if(this.controls){
			this.next.removeEventListener('click', this.slideshow.next);
			this.prev.removeEventListener('click',this.slideshow.previous);
		}
		this.slideshow.off('change', this.updateButtons);
		this.viewport.off('resize', this.resize);
	}

	buildAnimations = () =>{
		var t = new gsap.timeline({
			scrollTrigger: {
				trigger: this.slides,
				toggleActions: "restart complete complete complete",
				invalidateOnRefresh: true
			}
		})
	

		t.fromTo(this.slideBorder, 0.6,{scaleX:0},{scaleX:1, ease: "power3.inOut",stagger: 0.1}, 0);

		t.fromTo(this.slideBg, 0.8,{y:"100%"},{y:0, ease: "power3.out",stagger: 0.1}, 0.4);
		
		t.fromTo(this.slideText, 0.8,{y:15},{y:0, ease: "power3.out",stagger: 0.08}, 0.7);
		t.fromTo(this.slideText, 0.4,{alpha:0},{alpha:1,stagger: 0.08}, 0.7);
	}
}