import PfComponent                        from "../PfComponent";
import { ComponentsModule, RouterModule } from "libs";
import { FollowLinkModule }               from "../../modules";

export class FeedContributions extends PfComponent {
    constructor(e, b) {
        super(e, b);

        this.filtersEls = this.element.querySelectorAll('[data-filter-slug]');
        this.filtersSelectEls = this.element.querySelectorAll('.filters .filter select');
        this.filtersItemsEls = this.element.querySelectorAll('.filters .filter ul li')
        this.contentPosts = this.element.querySelector('.content-posts');
        this.headPosts = this.element.querySelector('.head-posts');
        this.readMoreBtn = this.element.querySelector('.actions .read-more');
        this.currentPage = this.element.dataset.page;
        this.typesEl = this.element.querySelectorAll('[data-filter-slug="post-type"] button');
        this.startDateEl = this.element.querySelector('[data-filter-slug="start-date"]');
        this.endDateEl = this.element.querySelector('[data-filter-slug="end-date"]');

        this.isHome = !!this.element.dataset.isHome;

        this.url = new URL(window.location);
        this.filters = {};

        // Set which filter can work aside another (every filter of a group can't work aside, they have to be in separate groups)
        this.filterGroups = [
            [
                'ordering',
            ], [
                'thematique',
                'type-de-contenu',
                'contributeur',
            ], [
                'post-type',
            ], [
                'start-date',
            ], [
                'end-date',
            ]
        ]

        this.buildFilters();
        this.setDomFilters();

    }

    buildFilters() {
        this.filters.page = this.currentPage;

        this.filtersEls.forEach(filterEl => {
            this.filters[filterEl.dataset.filterSlug] = this.url.searchParams.get(`filter-${filterEl.dataset.filterSlug}`);
        });
    }

    setDomFilters() {
        this.readMoreBtn.style.display = '';

        for (let filter in this.filters) {
            const filterEl = this.element.querySelector(`[data-filter-slug="${filter}"]`)
            if (filterEl) {
                const listItems = filterEl.querySelectorAll(`ul li, button`);
                if (listItems) {
                    listItems.forEach(item => {
                        item.classList.toggle('active', (item.dataset.value === this.filters[filter] || (!this.filters[filter] && item.dataset.value === 'default')));
                    })
                }
            }
        }
    }

    setFilter(filterSlug, filterValue) {
        const filterGroup = this.filterGroups.find(group => group.indexOf(filterSlug) !== -1);
        filterGroup.forEach(filter => {
            this.filters[filter] = null;
        })
        this.filters[filterSlug] = filterValue;

        this.setDomFilters()
        // this.goToFiltered(); // Call to go to the real route (with SSR)
    }

    async update() {
        const resp = await fetch(this.getURL().toString());
        const body = await resp.json();
        this.updateHistory();

        this.updateContent(body);
    }

    readMore = async () => {
        this.readMoreBtn.setAttribute('disabled', 'disabled');
        this.currentPage++;
        this.filters.page = this.currentPage;
        const resp = await fetch(this.getURL(true).toString());
        const body = await resp.json();
        this.updateHistory();
        if (body['last-page']) {
            this.currentPage--;
        }

        this.readMoreBtn.removeAttribute('disabled');

        this.updateContent(body, true);
    };

    updateContent(data, append = false) {
        if (append) {
            if (data.content) {
                this.contentPosts.innerHTML = this.contentPosts.innerHTML + data.content.rendered;
            }
            if (data.head) {
                this.headPosts.innerHTML = this.headPosts.innerHTML + data.head.rendered;
            }
        } else {
            if (data.content) {
                this.contentPosts.innerHTML = data.content.rendered;
            }
            if (data.head) {
                this.headPosts.innerHTML = data.head.rendered;
            }
        }

        if (data['last-page']) {
            this.readMoreBtn.style.display = 'none';
        } else {
            this.readMoreBtn.style.display = '';
        }

        this.bootstrap.get(ComponentsModule).components.setup()
        this.bootstrap.get(FollowLinkModule).update();
    }

    updateHistory() {
        history.replaceState(null, 'Filter Publications', this.url.toString()); // TODO: Fix this, it seems to break the history
    }

    getURL(isReadMore = false) {
        for (let filter in this.filters) {
            if (this.filters[filter]) {
                this.url.searchParams.set(`filter-${filter}`, this.filters[filter]);
            } else {
                this.url.searchParams.delete(`filter-${filter}`);
            }
        }

        const requestedUrl = new URL(this.wpEndpoint + ['pf/v1', isReadMore ? 'more-publications' : 'publications'].join('/'));
        requestedUrl.search = this.url.search;
        if (this.isHome) {
            requestedUrl.searchParams.set('is-home', 'true');
        }

        return requestedUrl;
    }

    goToFiltered() {
        this.bootstrap.get(RouterModule).set(this.url.toString());
    }

    onFilterSelectChange = (e) => {
        const filterSlug = e.currentTarget.closest('.filter').dataset.filterSlug;
        const value = e.currentTarget.value;
        this.setFilter(filterSlug, value);
        this.update();
    }

    onFilterItemClick = (e) => {
        const filter = e.currentTarget.closest('.filter');
        const select = filter.querySelector('select');
        if (select) {
            const value = (this.filters[filter.dataset.filterSlug] === e.currentTarget.dataset.value) ? 'default' : e.currentTarget.dataset.value;
            select.value = value;
            select.dispatchEvent(new Event('change'));
        }
    }

    onStartDateChange = (e) => {
        this.setFilter('start-date', new Date(e.currentTarget.value).getTime() * 0.001);
        this.update();
    }

    onEndDateChange = (e) => {
        this.setFilter('end-date', new Date(e.currentTarget.value).getTime() * 0.001);
        this.update();
    }

    onTypeButtonClick = (e) => {
        this.typesEl.forEach(el => {
            el.classList.toggle('active', el === e.currentTarget);
        })

        this.setFilter('post-type', e.currentTarget.dataset.value);
        this.update();
    }

    async attach() {
        this.filtersSelectEls.forEach(el => {
            el.addEventListener('change', this.onFilterSelectChange);
        })
        this.filtersItemsEls.forEach(el => {
            el.addEventListener('click', this.onFilterItemClick);
        })
        this.typesEl.forEach(el => {
            el.addEventListener('click', this.onTypeButtonClick);
        })

        if (this.readMoreBtn) {
            this.readMoreBtn.addEventListener('click', this.readMore);
        }

        if (this.startDateEl) {
            this.startDateEl.addEventListener('change', this.onStartDateChange);
        }

        if (this.endDateEl) {
            this.endDateEl.addEventListener('change', this.onEndDateChange);
        }
    }

    async detach() {
        this.filtersSelectEls.forEach(el => {
            el.removeEventListener('change', this.onFilterSelectChange);
        })
        this.filtersItemsEls.forEach(el => {
            el.removeEventListener('click', this.onFilterItemClick);
        })
        this.typesEl.forEach(el => {
            el.removeEventListener('click', this.onTypeButtonClick);
        })

        if (this.readMoreBtn) {
            this.readMoreBtn.removeEventListener('click', this.readMore);
        }
        if (this.startDateEl) {
            this.startDateEl.removeEventListener('change', this.onStartDateChange);
        }
        if (this.endDateEl) {
            this.endDateEl.removeEventListener('change', this.onEndDateChange);
        }

    }
}