import Video from './Video';

export default class Html5Video extends Video  {
	constructor (parent, options) {
		super();
		this.options = options;
		this.url = options.url ? options.url : '';
		this.player = document.createElement('video');
		this.player.src = this.url;
		this.player.style.width = '100%';
		if(this.options.autoplay) this.player.setAttribute('autoplay', 'true')
		parent.appendChild(this.player);

		this.player.addEventListener('canplay', this.init)
	}

	init = () => {
		this.loadCallback();
	}

	get volume () {

	}
	set volume (val) {

	}

	get duration () {
		return this.player.duration;
	}

	get time () {
		return this.player.currentTime;
	}
	play = () => {
		this.player.play()
		this._playing = true;
	}

	pause = () => {
		this.player.pause()
		this._playing = false;
	}

	seekTo = (relativeTime) => {
		this.player.currentTime = relativeTime * this.duration;
	}

	toggleMute = () => {
		this._mute = !this._mute;
		this.player.muted = this._mute;
	}

	mute = () => {
		this.player.muted = true
	}
	unMute = () => {
		this.player.muted = false
	}
}