import PfComponent             from "../PfComponent";
import { HorizontalSlideshow } from "libs";

export class Slideshow extends PfComponent {
    constructor(e, b) {
        super(e, b);

        this.slidesWrapper = this.element.querySelector('.slides');
        this.slides = Array.from(this.element.querySelectorAll('.slide'));
        this.slideshowEl = this.element.querySelector('.slideshow');

        this.nextBtn = this.element.querySelector('.controls .next');
        this.prevBtn = this.element.querySelector('.controls .previous');

        this.loop = typeof this.element.dataset.loop != "undefined" ? this.element.dataset.loop === "true" : true;

        this.slideshow = new HorizontalSlideshow(this.slidesWrapper, {
            // loop: this.loop,
            loop: false,
            snapping: typeof this.element.dataset.snapping != "undefined" ? this.element.dataset.snapping === "true" : true,
            reference: this.slideshowEl,
            autoResize: false,
            inputOptions: {
                wheel: false,
            }
        });

        this.noDesktop = this.element.classList.contains('no-dk');
        this.noDesktopSm = this.element.classList.contains('no-dk-sm');


    }

    init() {
        this.onMedia();
    }

    onResize = () => {
        this.slides.forEach(s => s.style.height = '');
        this.slidesWrapper.style.height = '';
        const maxSlideHeight = Math.max(...[...this.slides].map(slide => slide.offsetHeight));
        this.slidesWrapper.style.height = maxSlideHeight + 'px';
        this.slides.forEach(s => s.style.height = maxSlideHeight + 'px');
        this.slideshow.resize();
        // this.slideshow[this.slideshow.totalWidth > this.slideshowEl.offsetWidth ? 'attach' : 'detach']();
    }

    nextSlide = () => {
        if (this.slideshow.index === this.slideshow.max) {
            this.slideshow.index = this.slideshow.min;
        } else {
            this.slideshow.next();
        }
    }

    prevSlide = () => {
        if (this.slideshow.index === this.slideshow.min) {
            this.slideshow.index = this.slideshow.max;
        } else {
            this.slideshow.previous();
        }
    }

    onMedia = () => {
        if (this.noDesktop && this.viewport.medias.md || this.noDesktopSm && this.viewport.medias.sm) {
            this.slideshow.detach();
        } else {
            this.slideshow.attach();
        }
    }

    attach() {
        this.viewport.on('resize', this.onResize);
        this.viewport.on('media', this.onMedia);
        if (this.nextBtn) this.nextBtn.addEventListener('click', this.nextSlide);
        if (this.prevBtn) this.prevBtn.addEventListener('click', this.prevSlide);
        this.slideshow.on("change", this.onChangeSlide);
        this.onChangeSlide();
    }

    detach() {
        this.viewport.off('resize', this.onResize);
        this.viewport.off('media', this.onMedia);
        if (this.nextBtn) this.nextBtn.removeEventListener('click', this.nextSlide);
        if (this.prevBtn) this.prevBtn.removeEventListener('click', this.prevSlide);
        this.slideshow.detach();
        this.slideshow.off("change", this.onChangeSlide);
    }

    onChangeSlide = () => {
        this.slides.forEach(s => s.classList.remove("active"));
        this.slides[this.slideshow.index].classList.add("active");
        if (!this.loop) {
            if (this.slideshow.index === this.slideshow.min) {
                if (this.prevBtn) this.prevBtn.setAttribute("disabled", "disabled");
            } else {
                if (this.prevBtn) this.prevBtn.removeAttribute("disabled");
            }
            if (this.slideshow.index === this.slideshow.max) {
                if (this.nextBtn) this.nextBtn.setAttribute("disabled", "disabled");
            } else {
                if (this.nextBtn) this.nextBtn.removeAttribute("disabled");
            }
        }
    }

}