import { DefaultComponent, ViewportModule, PageModule } from "libs";

class PfComponent extends DefaultComponent {
    constructor(element, bootstrap) {
        super(element, bootstrap);
    }

    get viewport() {
        return this.bootstrap.get(ViewportModule).viewport;
    }

    get wpEndpoint() {
        return window.REST_URL;
    }

    get router() {
        return this.bootstrap.router;
    }

    get imageLoader() {
        return this.bootstrap.imageLoader;
    }

    get previousPage() {
        return this.bootstrap.get(PageModule).previous;
    }


}

export default PfComponent