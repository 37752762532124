import PfComponent from '../PfComponent';
import { gsap }    from 'gsap';


export class Publications extends PfComponent {
    constructor(e, b) {
        super(e, b);

        this.alaUne = this.element.querySelector('.contenualaune');
       
    }

    init() {
        this._alaUne = this.bootstrap.components.get(this.alaUne);
    }

    open() {
        let t = gsap.timeline();
        if (!!this._alaUne) {
            t.add(this._alaUne.open(),0);          
        }
        return t;
    }

    close() {

    }

    attach() {
    }

    detach() {
    }


}