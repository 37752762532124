import { Module, LoaderModule, ViewportModule} from "libs";
import {TransitionModule} from './TransitionModule';
import { gsap } from 'gsap';


class PfLoaderModule extends Module{
    constructor(bootstrap) {
        super(bootstrap);
        this.dependencies.add(LoaderModule);
        this.dependencies.add(TransitionModule);
        this.dependencies.add(ViewportModule);

        this.viewport = this.bootstrap.get(ViewportModule).viewport;

        this.loader = document.getElementById('loader');     
        this.content = this.loader.querySelector('.content');
 
        this.logo = this.loader.querySelector("img");
        this.circle = this.loader.querySelector('.circle-2');
        this.svg = this.loader.querySelector('circle');
        this.first = true;
    }

    showInterLoader = () => {
        this.showingInterloader = true;
        this.loader.style.display = "block";       
        this.loader.classList.add("interloader");       
        let t = gsap.timeline();
        t.fromTo(this.content,{y:"-100vh"},{y:0, ease:"power3.inOut", duration: 1},0);
        t.fromTo(this.loader,{y:"100vh"},{y:0, ease:"power3.inOut", duration:1},0);
        t.fromTo(this.content,{alpha:0},{alpha:1, duration:0.3},0.6)
        return t;
    }
    hideInterLoader = () => {        
        this.showingInterloader = false;
        let t = gsap.timeline();
        t.to(this.loader,{y:'-100vh', ease:"power3.inOut", duration: 1},0);
        t.to(this.content,{y:'100vh', ease:"power3.inOut", duration: 1},0);
        t.to(this.content,{alpha:0, duration:0.4},0);
        t.add(()=>{
            this.loader.style.display = "none";
        });
        return t;
    }




    async before() {
        let loader = this.bootstrap.get(LoaderModule).loader;

        loader.on('progress', (p) => {});}

    async after() {

        if(!this.first) return;
        this.first = false;
        let timeline = this.bootstrap.get(TransitionModule).timeline;
        let t = gsap.timeline({paued: true});
        t.to(this.logo,{opacity: 1, ease:"linear", duration: .75}, 0);
        t.to(this.logo,{scale: 1, ease:"power4.out", duration: 1.5}, 0);
        t.to(this.logo,{scale: 1, ease:"power4.out", duration: 1.5}, 0);
        t.to(this.circle,{strokeDashoffset:0, ease:"power4.inOut", duration: 2}, 0);
        t.to(this.loader,{y:'-100vh', ease:"power4.inOut", duration: 1},2);
        t.to(this.content,{y:'80vh', ease:"power4.inOut", duration: 1},2);
        t.add(()=>{
           this.loader.style.display = "none";
           

        });
        t.set([this.loader, this.content, this.logo], {y:0, alpha:1});
        timeline.shiftChildren(t.totalDuration()-0.6, true);
        timeline.add(t,0);

    }

}

export default PfLoaderModule;
export {PfLoaderModule};