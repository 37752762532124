import PfComponent from '../PfComponent';
import { gsap } from 'gsap';
import {ScrollTrigger } from 'gsap/ScrollTrigger';

class About extends PfComponent{
	constructor(e, b){
		super(e, b);

		this.gallery = this.element.querySelector('.gallery-wrapper');
		this.slideshow = this.element.querySelector(".gallery-wrapper .slideshow");
  
	};

	init(){
		this._slider = this.bootstrap.components.get(this.gallery);
		
	}

	attach(){
		this.build();
	}

	detach(){
	}

	build(){
		ScrollTrigger.create({
			trigger:this.slideshow,
			start:'50% bottom',
			end: "50% top",		
			onUpdate:(st)=>{
				var prg = st.progress * this._slider.slideshow.input.max.x
				this._slider.slideshow.input.target.x =  prg;
		

			}
		})
		ScrollTrigger.refresh(true);
	}

	open(){
        
		const tl = gsap.timeline();
      
		return tl;
	}

	close(){
		const tl = gsap.timeline();
		return tl;
	}





}

export { About };