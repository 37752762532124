import PfComponent from '../PfComponent';
import {TimelineMax, Power3, Linear, gsap, Expo} from 'gsap';

export class Search extends PfComponent {
	constructor(e, b) {
		super(e, b);

		this.searchForm = document.querySelector('.site-header .search-form');

		this.filterBtns = Array.from(this.element.querySelectorAll('.filter'));
		this.allBtn = this.element.querySelector('.filter-all');

		this.results = this.element.querySelector('.recherche-results');
		this.resultsTitle = this.element.querySelector('.recherche-results-title-inner');
		this.resultNumber = this.element.querySelector('.recherche-results-number');
		this.resultNumberS = this.element.querySelector('.recherche-results-number-s');
		this.resultsText = this.element.querySelector('.recherche-results-text');
		this.resultsTextContainer = this.element.querySelector('.recherche-results-text-container');
		this.resultsTextRemove = this.element.querySelector('.recherche-input-remove');
		this.thematiques = this.element.querySelector('.thematiques');
		this.filterContainer = this.element.querySelector('.filter-container');
		this.selectSort = this.element.querySelector('.select-sort');
		this.sortDropdownElements = this.element.querySelectorAll('.sort-container li');
		this.sortTitle = this.element.querySelector('.sort-title');
	}

	get query() {
		return this.element.dataset.query;
	}

	set query(query) {
		if (query !== this.element.dataset.query) {
			this.element.dataset.query = query;

			this.search();
		}
	}

	get filters() {
		if (this.allBtn.classList.contains('active'))
			return '';

		return this.filterBtns.filter(filter => filter.classList.contains('active'))[0].dataset.id;
	}

	get sortby() {
		return this.element.dataset.sortby;
	}

	set sortby(sortby) {
		if (sortby != this.element.dataset.sortby) {
			this.element.dataset.sortby = sortby;
			this.search();
		}
	}

	init() {
		// this.searchComponent = this.components.get(this.searchForm);
		// this.searchComponent.query = this.query;
		this.updateStats();
	}

	attach() {
		this.filterBtns.forEach(filter => filter.addEventListener('click', this.onFilterBtnClicked, true));
		this.allBtn.addEventListener('click', this.onFilterBtnClicked, true);
		this.selectSort.addEventListener('change', this.onSort);
		this.sortDropdownElements.forEach(el => el.addEventListener('click', this.onDropdownClick));
	}

	detach() {
		this.filterBtns.forEach(filter => filter.removeEventListener('click', this.onFilterBtnClicked, true));
		this.allBtn.removeEventListener('click', this.onFilterBtnClicked, true);
		this.selectSort.removeEventListener('change', this.onSort);
		this.sortDropdownElements.forEach(el => el.removeEventListener('click', this.onDropdownClick));
	}

	onDropdownClick = (e) => {
		this.selectSort.value = e.currentTarget.dataset.value;
		this.sortDropdownElements.forEach(el => el.classList.remove('active'));
		e.currentTarget.classList.add('active');
		this.onSort();
	}

	onSort = () => {
		this.sortTitle.innerHTML = this.selectSort.options[this.selectSort.selectedIndex].text;
		this.sortby = this.selectSort.value;
	}

	onFilterBtnClicked = (e) => {
		const id = e.currentTarget.getAttribute('data-id');

		if (id === 'all') {
			this.filterBtns.forEach(btn => btn.classList.remove('active'));
			this.allBtn.classList.add('active');
		} else {
			this.allBtn.classList.remove('active');

			this.filterBtns.forEach((btn) => {
				if (btn.dataset.id === id) {
					btn.classList.add('active');
				} else {
					btn.classList.remove('active');
				}
			})
		}

		this.search();
	}

	search = async () => {
		const response = await fetch(this.wpEndpoint + ['pf/v1', 'search-posts'].join('/'), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				'type': this.filters,
				'label': this.query,
				'sort': this.sortby
			})
		});
		const result = await response.json();
		this.results.innerHTML = result.result;
		this.results.querySelectorAll('.reading-time').forEach((element, index) => {
			this.split(element);
		});
		this.updateStats();
	}

    split = (e) => {
        var res = e.innerText.split('');
        var words = 0;
        var char = 0;
        res.forEach((element, index) => {
            res[index] = '<span class="char" data-char="' + element + '" style="--char-index:' + index + ';">' + element + '</span>';
            char++;
        });
        res = res.toString();
        e.innerHTML = res.replace(/,/g, "");
        e.style = '--word-total:' + words + '; --char-total:' + char + ';'
    }

	open = () => {
		document.body.classList.add('recherche')
		return new TimelineMax();
		// return this.cardsFadeIn();
	}

	close = () => {
		document.body.classList.remove('recherche');
		return new TimelineMax();
	}


	updateStats() {
		this.resultsText.innerHTML = this.query;
		const count = this.results.querySelectorAll('.card').length;
		this.resultNumber.innerHTML = count;

		this.thematiques.style.display = (count > 0) ? 'none' : 'block';
		this.resultNumberS.style.display = (count > 1) ? 'inline' : 'none';

		if(count == 0) {
			this.results.innerHTML = '<div class="font-rozha-one font-title text-blue">Aucun article ne correspond à votre recherche</div>'
			this.filterContainer.style.display = 'none'
		} else {
			this.filterContainer.style.display = ''
		}
		// this.resultsTextContainer.classList.toggle('active', this.query.length);
	}
}