import gsap from 'gsap';
import { throttle } from 'throttle-debounce';
import PfComponent from '../PfComponent';

export class ArticleTemoignage extends PfComponent{
	constructor(e, b){
		super(e, b);

        this.html = document.querySelector("html");
		this.cards 		= Array.from(this.element.querySelectorAll('.slide .picture'));
		this.popinsCtn 	= this.element.querySelector('.popins');
		if(this.popinsCtn){
			this.popins 	= Array.from(this.popinsCtn.querySelectorAll('.popin'));
			this.popinsBg 	= Array.from(this.popinsCtn.querySelectorAll('.popin .bg'));
			this.close = this.element.querySelector(".close");
		}
	

		this.currentPopin = null;

		this.throttledResizePopin = throttle(1000, false, this.resizePopin);
	}

	attach() {
		this.viewport.on('resize', this.resize);
		if(this.cards){
			this.cards.forEach(card => card.addEventListener('click', this.openPopin));
		}
		if(this.popinsBg){
			this.popinsBg.forEach(bg => bg.addEventListener('click', this.closePopin));
			this.close.addEventListener("click", this.closePopin);
		}
	

		this.router.on('change', this.closePopin);
	}

	detach() {
		this.viewport.off('resize', this.resize);
		if(this.cards){
			this.cards.forEach(card => card.removeEventListener('click', this.openPopin));
		}
		if(this.popinsBg){
			this.popinsBg.forEach(bg => bg.removeEventListener('click', this.closePopin));
			this.close.removeEventListener("click", this.closePopin);
		}
		this.router.off('change', this.closePopin);
	}

	openPopin = (e) => {
		const id = e.currentTarget.dataset.id;
		const popin = this.popins.find(popin => popin.dataset.id == id);

		this.html.classList.add('header-popin-opened', 'no-scroll');

		this.headerFixed 	= this.html.classList.contains('header-fixed');
		this.headerVisible 	= this.html.classList.contains('header-visible');

		// this.html.classList.remove('header-fixed', 'header-visible')

		popin.style.display = "block";
		this.popinsCtn.style.display = "block";
		this.currentPopin = popin;

		this.resizePopin();

		const wrapper 	= popin.querySelector('.wrapper');
		const bg 		= popin.querySelector('.bg');
		const title 	= wrapper.querySelector('.title');
		const text 	 	= wrapper.querySelector('.paragraph');
		const separator = wrapper.querySelector('.separator');
		const content 	= wrapper.querySelector('.content');
		const t = gsap.timeline();
		t.fromTo([this.close, bg], {opacity:0, duration: 0.5}, {opacity:1, ease:'none'}, 0);
		t.fromTo(wrapper, {y:wrapper.offsetHeight, duration: 1}, {y:0, ease:'expo.out'}, 0);

		t.fromTo(title, {y:40, duration: 1}, {y:0, ease:'expo.out'}, 0.2);
		t.fromTo(title, {opacity:0, duration: 0.5}, {opacity:1, ease:'none'}, 0.2);

		t.fromTo(text, {y:40, duration: 1}, {y:0, ease:'expo.out'}, 0.3);
		t.fromTo(text, {opacity:0, duration: 0.5}, {opacity:1, ease:'none'}, 0.3);

		t.fromTo(separator, {scaleX:10, duration: 1.4}, {scaleX:1, ease:'expo.out'}, 0);

		t.fromTo(content, {opacity:0, duration: 0.5}, {opacity:1, ease:'none'}, 0.5);

	}

	closePopin = (e) => {
		this.html.classList.remove('header-popin-opened', 'no-scroll');

		if(this.headerFixed) this.html.classList.add('header-fixed');
		if(this.headerVisible) this.html.classList.add('header-visible');

		const popin = this.currentPopin;

		this.currentPopin = null;
		if(popin){
			const wrapper 	= popin.querySelector('.wrapper');
			const bg 		= popin.querySelector('.bg');
			const title 	= wrapper.querySelector('.title');
			const text 	 	= wrapper.querySelector('.paragraph');
			const separator = wrapper.querySelector('.separator');
			const content 	= wrapper.querySelector('.content');
			const t = gsap.timeline();
			t.to(wrapper, {y:wrapper.offsetHeight, ease:'expo.out', duration: 1}, 0);
			t.to([this.close, bg], {opacity:0, ease:'none', duration: 0.5}, 0);

			t.to(title, {y:40, ease:'expo.in', duration: 0.5}, 0);
			t.to(title, {opacity:0, ease:'none', duration: 0.5}, 0);

			t.to(text, {y:40, ease:'expo.in', duration: 0.5}, 0);
			t.to(text, {opacity:0, ease:'none', duration: 0.5}, 0);

			t.to(separator, {scaleX:10, ease:'expo.in', duration: 0.5}, 0);

			t.to(content, {opacity:0, ease:'none', duration: 0.5}, 0);

			t.add(() => {
				popin.style.display = "none";
				this.popinsCtn.style.display = "none";
			})
		}
	}

    resize = () =>{
		this.throttledResizePopin();
	}

	resizePopin = () => {
		if(this.currentPopin){
			const iframe 	= this.currentPopin.querySelector('iframe');
			const content 	= this.currentPopin.querySelector('.content');

			if(iframe && content){
				iframe.removeAttribute('style');
				if(iframe.offsetHeight && iframe.offsetWidth){
					iframe.style.height = Math.ceil(content.offsetWidth * iframe.offsetHeight / iframe.offsetWidth)+'px';
					iframe.style.width = content.offsetWidth + 'px';
				}
				else this.throttledResizePopin();
			}
		}
	}

}