import { Module, ViewportModule, Fit} from "libs";


class FitModule extends Module{
    constructor(bootstrap) {
        super(bootstrap);

        this.dependencies.add(ViewportModule);

        this.onResize = this.onResize.bind(this);
    }

    async after() {
        return undefined;
    }

    async before() {
        return undefined;
    }

    onResize(){
        Fit('.fit');
    }

    async run() {
        if(!this.isAttached){
            this.bootstrap.get(ViewportModule).viewport.on('resize', this.onResize);
            this.isAttached = true;
        }
    }
}

export { FitModule };