import {
    Bootstrap,
    HistoryModule,
    RouterModule,
    HistoryLinkModule,
    AjaxPageModule,
    ImageLoaderModule,
    ComponentsModule,
    StateModule,
    ViewportModule
} from 'libs';

import {
    FitModule,
    TransitionModule,
    FollowLinkModule,
    PfLoaderModule,
    AnimationsModule,
    EmbedLoaderModule
} from '../modules';

export default class MyBoostrap extends Bootstrap {
    constructor() {
        super(
            HistoryModule,
            RouterModule,
            HistoryLinkModule,
            AjaxPageModule,
            ImageLoaderModule,
            ComponentsModule,
            StateModule,
            ViewportModule,
            TransitionModule,
            EmbedLoaderModule,
            FollowLinkModule,
            FitModule,
            PfLoaderModule,
            AnimationsModule
        );
    }


    get components() {
        return this.get(ComponentsModule).components;
    }

    get viewport() {
        return this.get(ViewportModule).viewport;
    }

    get router() {
        return this.get(RouterModule).router;
    }

    get imageLoader() {
        return this.get(ImageLoaderModule);
    }
}