import PfComponent from '../PfComponent';


export class Footer extends PfComponent{
	constructor(e, b){
		super(e, b);
	}

	init(){
	}

	attach(){
	}

	detach(){
	}


}