import PfComponent       from '../PfComponent';
import { gsap }          from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export class ContenuALaUne extends PfComponent {
    constructor(e, b) {
        super(e, b);

        this.mediaW = this.element.querySelector(".media-wrapper");
        this.mediaC = this.mediaW.querySelector(".media-content");
        this.media = this.mediaW.querySelector("img, video");
        this.content = this.element.querySelector('.content');
        this.titleHero = this.content.querySelector(".title-hero");
        this.categorie = this.content.querySelector('.categories');
        this.title = this.content.querySelector('.title');
        this.desc = this.content.querySelector('.desc');
        this.bottom = this.content.querySelector('.bottom');
    }

    attach() {
        this.build();
        this.viewport.on("resize", this.resize);

    }

    detach() {
        this.viewport.off("resize", this.resize);
    }

    open() {
        let t = gsap.timeline({paused: true});
        t.fromTo(this.mediaC, {scale: 1.28}, {scale: 1, ease: "power3.out", duration: 2}, 0);
        t.fromTo(this.titleHero, {y: 80}, {y: 0, ease: "power3.out", duration: 1.2}, 0);
        t.fromTo(this.titleHero, {alpha: 0}, {alpha: 1, duration: 0.6}, 0);
        t.fromTo([this.categorie, this.title, this.desc, this.bottom], {y: 110}, {
            y: 0,
            ease: "power3.out",
            duration: 1.2,
            stagger: 0.09
        }, 0);
        t.fromTo([this.categorie, this.title, this.desc, this.bottom], {alpha: 0}, {
            alpha: 1,
            duration: 0.6,
            stagger: 0.09
        }, 0);


        return t.play();
    }

    build() {
        this.computeAnim();
        this.st = ScrollTrigger.create({
            trigger: this.mediaW,
            start: 'top top',
            end: () => window.innerHeight + 'px top',
            pin: true,
            pinSpacing: false,
            onUpdate: (st) => {
                this.tParallax.progress(st.progress)
            }
        });
    }

    computeAnim() {
        this.tParallax = gsap.timeline({paused: true});
        this.tParallax.fromTo(this.mediaC, {y: 0}, {y: -this.viewport.height * 0.5}, 0);

        if (this.st) {
            this.tParallax.progress(this.st.progress);
        }
    }

    resize = () => {
        this.computeAnim()
    }
}