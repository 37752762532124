import PfComponent from '../PfComponent';
import { gsap }    from 'gsap';

class ReadingTime extends PfComponent {
    constructor(e, b) {
        super(e, b);
    };

    init() {
        var res = this.element.innerText.split('');
        var words = 0;
        var char = 0;
        res.forEach((element, index) => {
            res[index] = '<span class="char" data-char="' + element + '" style="--char-index:' + index + ';">' + element + '</span>';
            char++;
        });
        res = res.toString();
        this.element.innerHTML = res.replace(/,/g, "");
        this.element.style = '--word-total:' + words + '; --char-total:' + char + ';'
    }

    open() {

    }

    attach() {
    }

    detach() {
    }

    open() {
        const tl = gsap.timeline();
        return tl;
    }

    close() {
        const tl = gsap.timeline();
        return tl;
    }


}

export { ReadingTime };